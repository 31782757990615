import React from "react";
import { Card } from "./card";
export const Cardcontainer = (props) => {
  return (
    <div id="resources-card-container">
      <div className="intro-banner">
        <div className="overlay">
          <div className="container">
            <div className="row card-row">
                <Card title = {"Patent Registration and Protection"} text={'We help innovators secure patents for their inventions, ensuring legal protection against unauthorized use or replication. Our attorneys guide you through the entire process, from patent application to enforcement, safeguarding your intellectual property rights.'}/>
                <Card title = {"Trademark Filing and Enforcement"} text={'Our team assists in filing and registering trademarks to protect your brand identity, ensuring exclusive rights to your name, logo, or slogan. We also provide robust enforcement strategies to defend your trademark against infringement and misuse.'}/>
                <Card title = {"Copyright Law Services"} text={"We provide expert guidance on copyright registration, ensuring your creative works are protected under the law. Whether you're an artist, author, or content creator, our services help secure your rights and manage potential infringements."}/>
            </div>
            <div className="row card-row">
                <Card title = {"Trade Secrets Protection"} text={'Our firm offers comprehensive strategies to protect your trade secrets, ensuring that your confidential business information remains secure. We assist in drafting non-disclosure agreements (NDAs) and provide legal recourse if your secrets are misappropriated.'}/>
                <Card title = {"IP Litigation and Dispute Resolution"} text={'With extensive experience in intellectual property litigation, we represent clients in disputes related to patents, trademarks, copyrights, and trade secrets. Our team is committed to resolving conflicts efficiently, either through negotiation or in court.'}/>
                <Card title = {"Licensing and Commercialization"} text={'We help clients navigate the complexities of IP licensing, ensuring favorable agreements for the use of their intellectual property. Our goal is to maximize the value of your IP assets while protecting your legal interests in commercialization deals.'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
