import React from "react";
import intoImage from "../../img/intro-image.png"
export const Header = (props) => {
  return (
    <header id="header">
        <div className="overlay">
          <div className="container">
            <div className="row-header">
              <div className="intro-text">
                <h1 className="header-heading">PROTECTING YOUR INNOVATIONS</h1>
                <h1 className="header-heading">EMPOWERING YOUR BUSINESS</h1>
                <p className="header-description"> Comprehensive IP solutions for inventors, entrepreneurs, and businesses. Safeguard your intellectual property with expert legal guidance.</p>
                <button className="header-button">Get Started</button>
              </div>
              <div className="">
              <img src={require('../../img/intro-image.png')} />
              </div>
            </div>
          </div>
      </div>
    </header>
  );
};
