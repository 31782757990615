import React from "react";
import { Cardcontainer } from "../experties/card-container";
export const Experties = (props) => {
  return (
    <div id="experties" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title experties-title">
          <h2>Our Expertise in Intellectual Property</h2>
        </div>
        <div className="row">
        <div className="col-xs-6 col-md-3">
        <Cardcontainer />
        {/* data={props?.data?.Header} */}
          </div>
        </div>
      </div>
    </div>
  );
};
